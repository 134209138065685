import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import LoadingComponent from "../../components/loader/loader";
import { Tag } from "../../models/tag";
import { useStore } from "../../store/store";
import TagsList from "./tags-list/tags-list";
import "./tags.scss";

function Tags() {
  const { tagStore } = useStore();
  const {
    loadTags,
    loadSubTags,
    createTag,
    createSubTag,
    subTags,
    tags,
    editTag,
    editSubTag,
    deleteTag,
    deleteSubTag,
  } = tagStore;

  const [activeTag, setActiveTag] = useState<Tag | undefined>();
  const [value, setValue] = useState<string>("");

  const tagsArray = Array.from(tags);
  const subTagsArray = Array.from(subTags);

  useEffect(() => {
    loadTags();
    loadSubTags();
  }, [loadSubTags, loadTags]);

  const addNewTag = (isMain: boolean) => {
    if (isMain) {
      createTag(value);
      setValue("");
      return;
    }
    setValue("");
    createSubTag(value);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    const isTag = tagsArray.some((tag) => {
      return tag[0] === activeTag?.id;
    });

    if (event.currentTarget.value === "") {
      isTag ? deleteTag(activeTag?.id!) : deleteSubTag(activeTag?.id!);
      return;
    }

    if (!isTag && activeTag) {
      editSubTag(activeTag);
      setActiveTag(undefined);
      return;
    }

    editTag(activeTag!);
    setActiveTag(undefined);
  };

  const onTagClick = (tag: Tag) => {
    setActiveTag(tag);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveTag({ id: activeTag!.id, tag: event.currentTarget.value });
  };

  if (tagStore.loadingInitial) {
    return <LoadingComponent />;
  }

  return (
    <section className="tags">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <h1 className="tags__title">All Main Tags</h1>
            <TagsList
              tags={tagsArray}
              activeTag={activeTag}
              onChange={onChange}
              onBlur={onBlur}
              onClick={onTagClick}
            />
          </div>
          <div className="col-6">
            <h1 className="tags__title">All Sub Tags</h1>
            <TagsList
              tags={subTagsArray}
              activeTag={activeTag}
              onChange={onChange}
              onBlur={onBlur}
              onClick={onTagClick}
            />
          </div>
        </div>
        <div className="add-container">
          <input
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
            name="tag"
            id="tag"
            placeholder="Tag name"
            className="add-container__input"
          />
          <button
            onClick={() => addNewTag(true)}
            className="add-container__button"
          >
            <p className="add-container__button__text">Add main tag</p>
          </button>
          <button
            onClick={() => addNewTag(false)}
            className="add-container__button"
          >
            <p className="add-container__button__text">Add sub tag</p>
          </button>
        </div>
      </div>
    </section>
  );
}

export default observer(Tags);
