import { makeAutoObservable, runInAction } from "mobx";
import { Tag } from "../models/tag";
import agent from "../api/axios";
export default class TagStore {
  tags = new Map<string | undefined, Tag>();
  subTags = new Map<string | undefined, Tag>();
  selectedTag: Tag | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  private setTag(tag: Tag) {
    this.tags.set(tag.id, tag);
  }

  private setSubTag(subTag: Tag) {
    this.subTags.set(subTag.id, subTag);
  }

  private getTag(id: string) {
    return this.tags.get(id);
  }

  private getSubTag(id: string) {
    return this.subTags.get(id);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  loadTags = async () => {
    this.loadingInitial = true;
    try {
      const tagsArray = await agent.Tags.list();
      tagsArray.forEach((tag) => {
        this.setTag(tag);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadSubTags = async () => {
    this.loadingInitial = true;
    try {
      const subTagsArray = await agent.SubTags.list();
      subTagsArray.forEach((subTag) => {
        this.setSubTag(subTag);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadTag = async (id: string) => {
    let tag = this.getTag(id);
    if (tag) {
      this.selectedTag = tag;
      return tag;
    } else {
      this.loadingInitial = true;
      try {
        tag = await agent.Tags.details(id);
        this.setTag(tag);
        runInAction(() => {
          this.selectedTag = tag;
        });
        this.setLoadingInitial(false);
        return tag;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  loadSubTag = async (id: string) => {
    let subTag = this.getSubTag(id);
    if (subTag) {
      this.selectedTag = subTag;
      return subTag;
    } else {
      this.loadingInitial = true;
      try {
        subTag = await agent.SubTags.details(id);
        this.setSubTag(subTag);
        runInAction(() => {
          this.selectedTag = subTag;
        });
        this.setLoadingInitial(false);
        return subTag;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  createTag = async (tagName: string) => {
    this.loading = true;
    try {
      const createdTag = await agent.Tags.create(tagName);
      runInAction(() => {
        this.tags.set(createdTag.id, createdTag);
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  createSubTag = async (subTagName: string) => {
    this.loading = true;
    try {
      const createdSubTag = await agent.SubTags.create(subTagName);
      runInAction(() => {
        this.subTags.set(createdSubTag.id, createdSubTag);
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  editTag = async (tag: Tag) => {
    this.loading = true;
    try {
      const editedTag = await agent.Tags.update(tag);
      runInAction(() => {
        this.tags.set(editedTag.id, editedTag);
        this.selectedTag = tag;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  editSubTag = async (subTag: Tag) => {
    this.loading = true;
    try {
      const editedSubTag = await agent.SubTags.update(subTag);
      runInAction(() => {
        this.subTags.set(editedSubTag.id, editedSubTag);
        this.selectedTag = subTag;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteTag = async (id: string) => {
    this.loading = true;
    try {
      await agent.Tags.delete(id);
      runInAction(() => {
        this.tags.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteSubTag = async (id: string) => {
    this.loading = true;
    try {
      await agent.SubTags.delete(id);
      runInAction(() => {
        this.subTags.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
