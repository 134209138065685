import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/axios";
import { Message } from "../models/message";

export default class MessageStore {
  messages = new Map<string, Message>();
  selectedMessage: Message | undefined = undefined;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  private setMessages(message: Message) {
    this.messages.set(message.id, message);
  }

  private setMessage(message: Message) {
    message.read = true;
    this.messages.set(message.id, message);
  }

  private getMessage(id: string) {
    return this.messages.get(id);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  loadMessages = async () => {
    this.loadingInitial = true;
    try {
      const messagesArray = await agent.Messages.list();
      messagesArray.forEach((message) => {
        this.setMessages(message);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  editMessage = async (id: string) => {
    this.loading = true;
    try {
      const message = await agent.Messages.read(id);
      runInAction(() => {
        this.messages.set(id, message);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  loadMessage = async (id: string) => {
    let message = this.getMessage(id);
    if (message) {
      this.selectedMessage = message;
      return message;
    } else {
      this.loadingInitial = true;
      try {
        message = await agent.Messages.details(id);
        this.setMessage(message!);
        runInAction(() => {
          this.selectedMessage = message;
        });
        this.setLoadingInitial(false);
        return message;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  deleteMessage = async (id: string) => {
    this.loading = true;
    try {
      await agent.Messages.delete(id);
      runInAction(() => {
        this.messages.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
