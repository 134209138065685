import React, { ChangeEvent } from "react";
import { Header } from "semantic-ui-react";
import "./text-input.scss";

interface Props {
  placeholder: string;
  name: string;
  value: string | undefined;
  onChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  rows?: number;
  title: string;
  className?: string;
  containerClassName?: string;
  type?: string;
  disabled?: boolean;
}

function TextInput({
  placeholder,
  name,
  value,
  onChange,
  rows,
  title,
  className,
  containerClassName,
  type,
  disabled = false,
}: Props) {
  return (
    <div className={`text-input ${containerClassName}`}>
      <Header content={title} sub color="teal" />
      {rows ? (
        <textarea
          className={`text-input__input ${className}`}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          rows={rows}
          disabled={disabled}
        />
      ) : (
        <input
          className={`text-input__input ${className}`}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          type={type}
          disabled={disabled}
        />
      )}
    </div>
  );
}

export default TextInput;
