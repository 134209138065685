import React, { useRef, useState } from "react";
import { Button, Header } from "semantic-ui-react";
import agent from "../../api/axios";
import { DBFile } from "../../models/file";
import "./image-picker.scss";

interface ImagePickerProps {
  imagePath: string | undefined;
  uploadImage: (image: DBFile) => void;
  deleteImage: () => void;
  name: string;
}

export default function ImagePicker({
  imagePath,
  uploadImage,
  deleteImage,
  name,
}: ImagePickerProps) {
  const [image, setImage] = useState<DBFile | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [target, setTarget] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const pickImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      setSelectedImage(event.currentTarget.files[0]);
    }
  };

  const uploadImageHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true);
    setTarget(event.currentTarget.name);

    if (selectedImage) {
      try {
        agent.Images.createOne(selectedImage).then((result) => {
          setLoading(false);
          setImage(result);
          uploadImage(result);
          setTarget("");
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteImageHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true);
    setTarget(event.currentTarget.name);
    if (image) {
      agent.Images.delete(image.id).then(() => {
        setImage(null);
        deleteImage();
        setSelectedImage(null);
        setLoading(false);
      });
    }
  };

  return (
    <>
      <Header content={name} sub color="teal" />
      <section className="image-picker">
        <input
          ref={inputRef}
          style={{ display: "none" }}
          type="file"
          onChange={pickImage}
        />
        <div className="image-picker__image-name-container">
          {image ? (
            <p className="image-name">{image.path}</p>
          ) : imagePath ? (
            <p className="image-name">{imagePath}</p>
          ) : (
            <p className="placeholder">{name}</p>
          )}
        </div>
        <Button
          type="button"
          style={{ backgroundColor: "#FA9E00" }}
          className="image-picker__button"
          onClick={() => inputRef.current?.click()}
        >
          <p className="image-picker__button__text">Pick Image</p>
        </Button>
        {selectedImage && (
          <Button
            type="button"
            name={image?.id + "1"}
            loading={loading && target === image?.id + "1"}
            className={`image-picker__button${
              !selectedImage ? "--disabled" : ""
            }`}
            style={{ backgroundColor: "#037d50" }}
            onClick={uploadImageHandler}
          >
            <p
              className={`image-picker__button__text${
                loading && target === image?.uid + "1" ? "--loading" : ""
              }`}
            >
              Upload Image
            </p>
          </Button>
        )}
        {image && (
          <Button
            type="button"
            name={image.id}
            loading={loading && target === image.id}
            style={{ backgroundColor: "#cc5951" }}
            className="image-picker__button"
            onClick={deleteImageHandler}
          >
            <p
              className={`image-picker__button__text${
                loading && target === image.id ? "--loading" : ""
              }`}
            >
              Delete Image
            </p>
          </Button>
        )}
      </section>
    </>
  );
}
