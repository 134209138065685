import { useEffect } from "react";

import Offers from "./offers-list/offers-list";
import { useStore } from "../../store/store";
import LoadingComponent from "../../components/loader/loader";
import { observer } from "mobx-react-lite";

function JobOffers(): JSX.Element {
  const { offerStore } = useStore();
  const { loadOffers } = offerStore;

  useEffect(() => {
    loadOffers();
  }, [loadOffers]);

  if (offerStore.loadingInitial) {
    return <LoadingComponent />;
  }

  return <Offers />;
}

export default observer(JobOffers);
