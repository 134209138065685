import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import LoadingComponent from "../../components/loader/loader";
import { useStore } from "../../store/store";
import NewsList from "./news/news-list";

export default observer(function Blog() {
  const { newsStore } = useStore();
  const { loadPosts, loadingInitial } = newsStore;

  useEffect(() => {
    loadPosts();
  }, [loadPosts]);

  if (loadingInitial) {
    return <LoadingComponent />;
  }

  return <NewsList />;
});
