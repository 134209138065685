/* eslint-disable no-extend-native */
import { Tag } from "../models/tag";
import { BlogTag } from "../models/blog";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";

export function mapToDropdownArray(map: Map<string | undefined, Tag>) {
  const array = Array.from(map, ([_, tag]) => ({
    value: tag.id,
    label: tag.tag,
  }));

  return array;
}

export function tagsToSelectPicker(tags: BlogTag | BlogTag[]) {
  if (!Array.isArray(tags)) {
    return {
      value: tags.tagId,
      label: tags.tagName,
    };
  }

  return tags.map((tag) => {
    return {
      value: tag.tagId,
      label: tag.tagName,
    };
  });
}

export function selectPickerToTag(
  data: { label: string; value: string }[]
): BlogTag[] {
  return data.map((element) => {
    return {
      tagId: element.value,
      tagName: element.label,
    };
  });
}

export const convertHtmlToContent = (content: any) => {
  const blocksFromHtml = htmlToDraft(content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};
