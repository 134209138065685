/* eslint-disable @typescript-eslint/no-unused-expressions */
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Segment, Item, Button } from "semantic-ui-react";
import LoadingComponent from "../../../components/loader/loader";
import { useStore } from "../../../store/store";

function MessageItem() {
  const { messageStore } = useStore();
  const { loadMessage, selectedMessage, editMessage, deleteMessage, loading } =
    messageStore;

  let [searchParams] = useSearchParams();
  let id = searchParams.get("id");

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      loadMessage(id).then((message) => {
        if (message) {
          editMessage(id!);
        }
      });
    }
  }, [editMessage, id, loadMessage]);

  if (!id) {
    return (
      <Segment style={{ overflow: "auto", maxHeight: 500, height: 300 }}>
        No message selected yet.
      </Segment>
    );
  }

  if (loading) {
    return (
      <Segment style={{ overflow: "auto", maxHeight: 500, height: 300 }}>
        <LoadingComponent content="Loading message..." />
      </Segment>
    );
  }

  return (
    <Segment style={{ overflow: "auto", maxHeight: 500, height: 300 }}>
      <Item.Group>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Item.Header>{dayjs(selectedMessage?.date).format('DD.MM.YYYY HH:MM')}</Item.Header>
          <Button
            negative
            content="Delete"
            loading={loading}
            onClick={() => deleteMessage(id!).then(() => navigate("/contact"))}
          />
        </div>
        <Item>
          <Item.Content>
            <Item.Header>Full Name: {selectedMessage?.fullName}</Item.Header>
            <Item.Description>
              Phone number: {selectedMessage?.phone}
            </Item.Description>
            <Item.Description>Email: {selectedMessage?.email}</Item.Description>
            <Item.Description>
              Message: {selectedMessage?.message}
            </Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  );
}

export default observer(MessageItem);
