import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Button, Container, Header, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/store";
import TextInput from "./text-input";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingComponent from "../../components/loader/loader";

function Login() {
  const navigate = useNavigate();
  const { userStore } = useStore();
  const [successful, setSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/users/me`);

        if (response.status === 200) {
          setSuccessful(true);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    getUser();

    if (successful) {
      navigate("home");
    }
  }, [navigate, successful]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Container>
      <Formik
        initialValues={{ email: "", password: "", error: null }}
        onSubmit={(values, { setErrors }) =>
          userStore
            .login(values.email, values.password)
            .catch(() => setErrors({ error: "Invalid email of password" }))
            .then(() => navigate("/home"))
        }
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <Form className="ui form" onSubmit={handleSubmit} autoComplete="off">
            <Header
              as="h2"
              content="Login to Admin Panel"
              color="teal"
              textAlign="center"
            />
            <TextInput name="email" placeholder="Email" />
            <TextInput name="password" placeholder="Password" type="password" />
            <ErrorMessage
              name="error"
              render={() => (
                <Label
                  style={{ marginBottom: 10 }}
                  basic
                  color="red"
                  content={errors.error}
                />
              )}
            />
            <Button
              loading={isSubmitting}
              positive
              content="Login"
              type="submit"
              fluid
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default observer(Login);
