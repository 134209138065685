import React from "react";
import 'draft-js/dist/Draft.css';
import "./App.scss";
import { Routes, Route } from "react-router-dom";
import routes from "./routes";
import { Layout } from "./components/layout/layout";

function App() {
  return (
    <Layout>
      <Routes>
        {routes.map(({ path, Component }, key) => (
          <Route path={path} key={key} element={<Component />} />
        ))}
      </Routes>
    </Layout>
  );
}

export default App;
