/* eslint-disable no-useless-concat */
export const menuItems = [
  {
    title: "Tags",
    url: "/tags",
  },
  {
    title: "Job Offers",
    url: "/offers",
  },
  {
    title: "Blog",
    url: "/blog",
  },
  {
    title: "Contact",
    url: "/contact",
  },
];
