import { observer } from "mobx-react-lite";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import arrow from "../../../assets/icons/title-arrow.svg";
import { useStore } from "../../../store/store";

import "./offers-list.scss";

function Offers(): JSX.Element {
  const { offerStore } = useStore();
  const { offers } = offerStore;

  const offersArray = Array.from(offers);

  const navigate = useNavigate();

  return (
    <section className="offers">
      <div className="container offers-block">
        <div className="offers-block__offers-grid">
          {offersArray.map(([id, offer]) => (
            <Link
              to={`/edit-offer/${id}`}
              className="offers-block__offers-grid__offer"
              key={offer.id}
            >
              <div className="title">
                {offer.title}
                <img src={arrow} alt="Arrow" className="title__img" />
              </div>
              <div className="description">{offer.tags.join(", ")}</div>
            </Link>
          ))}
        </div>
        <Button
          inverted
          size="large"
          color="orange"
          onClick={() => navigate("/create-offer")}
        >
          Create
        </Button>
      </div>
    </section>
  );
}

export default observer(Offers);
