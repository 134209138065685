import React from "react";
import { useLocation } from "react-router-dom";
import "./layout.scss";
import { Navbar } from "./navbar/navbar";

export interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps): JSX.Element {
  const location = useLocation();
  const showNavbar = location.pathname === "/";

  return (
    <div className="page">
      <header className="page__header">{!showNavbar && <Navbar />}</header>

      <main className="page__content">
        <div className="page__content__paddings">{children}</div>
      </main>
    </div>
  );
}
