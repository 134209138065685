import React, { useEffect, useState } from "react";
import "./navbar.scss";
import logo from "../../../assets/embrox-solutions-logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { menuItems } from "./menu-item/menu-items";
import { useStore } from "../../../store/store";

export function Navbar(): JSX.Element {
  const [toggleNav, setToggleNav] = useState(false);
  const location = useLocation();
  const { userStore } = useStore();

  const openNavBar = (): void => {
    setToggleNav(!toggleNav);
  };

  useEffect(() => {
    setToggleNav(false);
  }, [location]);

  const navigate = useNavigate();

  const navigateToHome = (): void => {
    navigate("/home");
  };

  function logout() {
    userStore.logout();
    navigate("/");
  }

  if (window.localStorage.getItem("jwt") !== null) {
    return (
      <div className="navbar fixed">
        <div className="container">
          <Link to="home" onClick={navigateToHome} className="navbar__logo">
            <img src={logo} className="img-responsive" alt="Embrox Solutions" />
          </Link>
          <div className="navbar__nav">
            <ul>
              {menuItems.map((menu, index) => (
                <li key={index} className="navbar__nav">
                  <Link to={menu.url} className="navbar__nav__link">
                    {menu.title}
                  </Link>
                </li>
              ))}
              <div className="navbar__nav__link" onClick={() => logout()}>
                Logout
              </div>
            </ul>
          </div>
          <div className="navbar__small-nav">
            <button type="button" onClick={openNavBar} className="main-button">
              Menu
            </button>
            <div className="menu">
              {toggleNav && (
                <>
                  {menuItems.map((menu, index) => (
                    <Link
                      to={menu.url}
                      className="navbar__nav__link"
                      key={index}
                    >
                      {menu.title}
                    </Link>
                  ))}
                  <div className="navbar__nav__link" onClick={() => logout()}>
                    Logout
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div></div>;
}
