import React, { useEffect, useState } from "react";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./blog-form.scss";
import { CreatePost, Post } from "../../models/blog";
import draftToHtml from "draftjs-to-html";
import { SelectDropdown } from "../../components/select-dropdown/select-dropdown";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/store";
import {
  tagsToSelectPicker,
  mapToDropdownArray,
  selectPickerToTag,
  convertHtmlToContent,
} from "../../utils/helper-functions";
import LoadingComponent from "../../components/loader/loader";
import TextInput from "../../components/text-input/text-input";
import ImagePicker from "../../components/image-picker/image-picker";
import { DBFile } from "../../models/file";
import { Button } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";

export default observer(function BlogForm() {
  const { tagStore, newsStore } = useStore();
  const { subTags, tags, loadSubTags, loadTags } = tagStore;
  const { createPost, editPost, loadPost, deletePost } = newsStore;

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const [post, setPost] = useState<Post>({
    id: "",
    title: "",
    description: "",
    url: "",
    date: new Date(),
    subTags: [],
    mainTag: { tagId: "", tagName: "" },
    bannerImage: "",
    descriptionImage: "",
    previewBannerImage: "",
    content: null,
    active: true,
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [loading, setLoading] = useState(false);
  const [blogImage, setBlogImage] = useState<DBFile | null>();

  const [convertedContent, setConvertedContent] = useState<string>("");

  useEffect(() => {
    loadSubTags();
    loadTags();
  }, [loadSubTags, loadTags]);

  useEffect(() => {
    if (id) {
      loadPost(id).then((post) => {
        setPost(post!);
        setEditorState(convertHtmlToContent(post?.content[0]));
        setConvertedContent(post?.content[0]);
      });
    }
  }, [id, loadPost]);

  const handleEditorChange = (state: React.SetStateAction<EditorState>) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setConvertedContent(currentContentAsHTML);
  };

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setPost({ ...post, [name]: value });
  };

  const finishEditing = () => {
    setLoading(true);

    const news: CreatePost = {
      title: post.title,
      description: post.description,
      url: post.url,
      mainTagId: post.mainTag.tagId,
      subTagIds: post.subTags.map((subTag) => {
        return subTag.tagId;
      }),
      content: [convertedContent],
      date: post.date,
      bannerImage: post.bannerImage,
      descriptionImage: post.descriptionImage,
      previewBannerImage: post.previewBannerImage,
      active: true,
    };

    if (id) {
      editPost(id, news)
        .then(() => {
          navigate("/blog");
        })
        .catch((error) => console.log(error));
    } else {
      createPost(news)
        .then(() => {
          navigate("/blog");
        })
        .catch((error) => console.log(error));
    }
  };

  const deletePostHandler = () => {
    if (id) {
      deletePost(id).then(() => {
        navigate("/blog");
      });
    }
  };

  const handleMainTagSelect = (data: { value: string; label: string }) => {
    setPost({ ...post, mainTag: { tagId: data.value, tagName: data.label } });
  };

  const handleSubTagsSelect = (data: any) => {
    setPost({ ...post, subTags: selectPickerToTag(data) });
  };

  const setBannerImage = (image: DBFile) => {
    setPost({ ...post, bannerImage: image.path });
  };

  const deleteBannerImage = () => {
    setPost({ ...post, bannerImage: "" });
  };

  const setDescriptionImage = (image: DBFile) => {
    setPost({ ...post, descriptionImage: image.path });
  };

  const deleteDescriptionImage = () => {
    setPost({ ...post, descriptionImage: "" });
  };

  const setMobileBannerImage = (image: DBFile) => {
    setPost({ ...post, previewBannerImage: image.path });
  };

  const deleteMobileBannerImage = () => {
    setPost({ ...post, previewBannerImage: "" });
  };

  if (tagStore.loadingInitial) {
    return <LoadingComponent />;
  }

  return (
    <div className="blog">
      <div className="container">
        <ImagePicker
          imagePath={post.bannerImage}
          uploadImage={setBannerImage}
          name="Banner image"
          deleteImage={deleteBannerImage}
        />
        <ImagePicker
          imagePath={post.previewBannerImage}
          uploadImage={setMobileBannerImage}
          name="Mobile Banner image"
          deleteImage={deleteMobileBannerImage}
        />
        <ImagePicker
          imagePath={post.descriptionImage}
          uploadImage={setDescriptionImage}
          deleteImage={deleteDescriptionImage}
          name="Description image"
        />
        <div className="blog__text-inputs">
          <TextInput
            name="title"
            placeholder="Title"
            title="Title"
            value={post.title}
            onChange={onChange}
          />
          <TextInput
            name="description"
            placeholder="Description"
            title="Description"
            value={post.description}
            onChange={onChange}
            rows={4}
          />
          <TextInput
            name="url"
            placeholder="Url"
            title="Url"
            value={post.url}
            onChange={onChange}
          />
        </div>
        <div className="blog__dropdowns-container">
          <SelectDropdown
            options={mapToDropdownArray(tags)}
            selectedOptions={tagsToSelectPicker(post.mainTag)}
            handleSelect={handleMainTagSelect}
            placeholder="main tag"
            title="Select main tag"
            isMulti={false}
            isSearchable={true}
          />
          <SelectDropdown
            options={mapToDropdownArray(subTags)}
            selectedOptions={tagsToSelectPicker(post.subTags)}
            handleSelect={handleSubTagsSelect}
            placeholder="sub tag"
            title="Select sub tags"
            isMulti={true}
            isSearchable={true}
          />
          <div className="blog__datepicker-container">
            <h2>Select date</h2>
            <DatePicker
              selected={new Date(post.date)}
              onChange={(date: Date) => setPost({ ...post, date })}
              className={"datepicker"}
            />
          </div>
        </div>
        <div className="blog__blog-images-container">
          <ImagePicker
            imagePath={blogImage?.path}
            uploadImage={setBlogImage}
            deleteImage={() => setBlogImage(null)}
            name="Here you can add image to get url"
          />
        </div>
        <div className="blog__editor">
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              fontFamily: { options: ["Avenir"] },
              colorPicker: {
                colors: ["#333333", "#ff6600"],
              },
            }}
          />
        </div>
        <div className="blog__control-button-container">
          <Button
            loading={loading}
            onClick={finishEditing}
            positive
            content="Finish"
            type="submit"
            fluid
          />
          <Button
            disabled={!id}
            onClick={deletePostHandler}
            negative
            content="Delete"
            type="submit"
            fluid
          />
        </div>
      </div>
    </div>
  );
});
