import React from "react";
import { Container, Header, Image } from "semantic-ui-react";
import image from "../../assets/joakim-honkasalo-GZa4QFmv0Zg-unsplash.jpg";

function Home() {
  return (
    <Container style={{ marginTop: "50px" }}>
      <Image src={image} fluid />
      <Header style={{ textAlign: "center" }} size="huge">
        It`s home page! Welcome and have a nice day!
      </Header>
    </Container>
  );
}

export default Home;
