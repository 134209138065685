import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useStore } from "../../../store/store";
import { NewsPreview } from "../news-preview/news-preview";
import "./news-list.scss";

function NewsList(): JSX.Element {
  const { newsStore } = useStore();
  const { posts } = newsStore;

  const news = Array.from(posts);

  return (
    <section className="news">
      <div className="container">
        <Link to={"/create-blog"}>
          <Button fluid color="green" content="Create New Blog" />
        </Link>
        <div className="row">
          {news.map(([id, post]) => (
            <div className="col-6" key={id}>
              <NewsPreview news={post} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default observer(NewsList);
