import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/axios";
import { Offer } from "../models/offer";
export default class OfferStore {
  offers = new Map<string, Offer>();
  selectedOffer: Offer | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  private setOffer(offer: Offer) {
    this.offers.set(offer.id, offer);
  }

  private getOffer(id: string) {
    return this.offers.get(id);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  loadOffers = async () => {
    this.loadingInitial = true;
    try {
      const offersArray = await agent.Offers.list();
      offersArray.forEach((offer) => {
        this.setOffer(offer);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadOffer = async (id: string) => {
    let offer = this.getOffer(id);
    if (offer) {
      this.selectedOffer = offer;
      return offer;
    } else {
      this.loadingInitial = true;
      try {
        offer = await agent.Offers.details(id);
        this.setOffer(offer);
        runInAction(() => {
          this.selectedOffer = offer;
        });
        this.setLoadingInitial(false);
        return offer;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  createOffer = async (offer: Offer) => {
    this.loading = true;
    try {
      const createdOffer = await agent.Offers.create(offer);
      runInAction(() => {
        this.offers.set(createdOffer.id, createdOffer);
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  editOffer = async (offer: Offer) => {
    this.loading = true;
    try {
      const editedOffer = await agent.Offers.update(offer);
      runInAction(() => {
        this.offers.set(editedOffer.id, editedOffer);
        this.selectedOffer = offer;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteOffer = async (id: string) => {
    this.loading = true;
    try {
      await agent.Offers.delete(id);
      runInAction(() => {
        this.offers.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
