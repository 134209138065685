import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Container, Grid } from "semantic-ui-react";
import { useStore } from "../../store/store";
import ContactsList from "./contact-list/contact-list";
import MessageItem from "./message-list/message-list";

import "./contact.scss";

function Contacts() {
  const { messageStore } = useStore();
  const { loadMessages } = messageStore;

  useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  return (
    <Container style={{ marginTop: "3em" }}>
      <Grid>
        <Grid.Column width={5}>
          <ContactsList />
        </Grid.Column>
        <Grid.Column width={11}>
          <MessageItem />
        </Grid.Column>
      </Grid>
    </Container>
  );
}

export default observer(Contacts);
