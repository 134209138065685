import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Offer } from "../../models/offer";
import { useStore } from "../../store/store";
import LoadingComponent from "../../components/loader/loader";
import { Formik } from "formik";
import { Header, Form, Button, Container } from "semantic-ui-react";
import "./job-offer-form.scss";
import { observer } from "mobx-react-lite";
import ListInput from "../../components/text-input/list-input";
import TextInput from "../../components/text-input/text-input";
import ImagePicker from "../../components/image-picker/image-picker";
import { DBFile } from "../../models/file";

function OfferForm() {
  const { offerStore } = useStore();
  const {
    createOffer,
    loadingInitial,
    loadOffer,
    editOffer,
    deleteOffer,
    loading,
  } = offerStore;

  const navigate = useNavigate();

  const [offer, setOffer] = useState<Offer>({
    id: "",
    url: "",
    enabled: false,
    title: "",
    tags: [""],
    text: "",
    description: "",
    skillsAndQualifications: [""],
    advantages: [""],
    weOffers: [""],
    responsibilities: [""],
    wouldBePlus: [""],
    headerBanner: "",
    mobileBanner: "",
  });

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      loadOffer(id).then((offer) => {
        setOffer(offer!);
      });
    }
  }, [id, loadOffer]);

  function hideOrReviveOffer() {
    const enabled = offer.enabled;
    setOffer({ ...offer, enabled: !enabled });
  }

  function getListInputProps(
    propName:
      | "tags"
      | "skillsAndQualifications"
      | "advantages"
      | "weOffers"
      | "responsibilities"
      | "wouldBePlus"
  ) {
    return {
      array: offer[propName],
      placeholder: propName.charAt(0).toUpperCase() + propName.slice(1),
      removeInputField: deleteInputFieldBuilder(propName),
      addInputField: addInputField(propName),
      handleInputChange: handleChangeListElement(propName),
    };
  }

  function handleChangeListElement(
    propName:
      | "tags"
      | "skillsAndQualifications"
      | "advantages"
      | "weOffers"
      | "responsibilities"
      | "wouldBePlus"
  ) {
    return function (event: ChangeEvent<HTMLTextAreaElement>, index: number) {
      const { value } = event.target;
      const temp = offer[propName];
      temp![index] = value;
      setOffer({
        ...offer,
        [event.target.name]: temp,
      });
    };
  }

  function addInputField(
    propName:
      | "tags"
      | "skillsAndQualifications"
      | "advantages"
      | "weOffers"
      | "responsibilities"
      | "wouldBePlus"
  ) {
    return function () {
      setOffer({ ...offer, [propName]: [...offer[propName], ""] });
    };
  }

  function deleteInputFieldBuilder(
    propName:
      | "tags"
      | "skillsAndQualifications"
      | "advantages"
      | "weOffers"
      | "responsibilities"
      | "wouldBePlus"
  ) {
    return function (index: number) {
      const rows = [...offer[propName]];
      if (rows.length > 1) {
        rows.splice(index, 1);
      }
      setOffer({
        ...offer,
        [propName]: rows,
      });
    };
  }

  function handleInputChange(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const { name, value } = event.target;
    setOffer({ ...offer, [name]: value });
  }

  function submitFormHandler(offer: Offer) {
    if (id) {
      editOffer(offer).then(() => {
        navigate("/offers");
      });
    } else {
      createOffer(offer).then(() => {
        navigate("/offers");
      });
    }
  }

  const setBannerImage = (image: DBFile) => {
    setOffer({ ...offer, headerBanner: image.path });
  };

  const setMobileBannerImage = (image: DBFile) => {
    setOffer({ ...offer, mobileBanner: image.path });
  };

  const deleteBannerImage = () => {
    setOffer({ ...offer, headerBanner: "" });
  };

  const deleteMobileBannerImage = () => {
    setOffer({ ...offer, mobileBanner: "" });
  };

  if (loadingInitial) {
    return <LoadingComponent content="Loading offer..." />;
  }

  return (
    <Container style={{ marginTop: "7em" }}>
      {id && (
        <Button
          loading={loading}
          floated="right"
          type="button"
          content="Delete"
          negative
          style={{ marginBottom: "25px" }}
          onClick={() => deleteOffer(id).then(() => navigate("/offers"))}
        />
      )}
      <Formik
        enableReinitialize
        initialValues={offer}
        onSubmit={(values) => submitFormHandler(values)}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <Header content="Offer Description" color="orange" />
            <Form
              className="ui form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <TextInput
                title="Title"
                placeholder="Title"
                value={offer.title}
                name="title"
                onChange={handleInputChange}
                containerClassName="job-offer-form__input"
              />
              <TextInput
                title="Url"
                placeholder="Url"
                value={offer.url}
                name="url"
                onChange={handleInputChange}
              />
              <ImagePicker
                imagePath={offer.headerBanner}
                uploadImage={setBannerImage}
                deleteImage={deleteBannerImage}
                name={"Banner Image"}
              />
              <ImagePicker
                imagePath={offer.mobileBanner}
                uploadImage={setMobileBannerImage}
                deleteImage={deleteMobileBannerImage}
                name={"Mobile Banner Image"}
              />
              <Header content="Technologies" sub color="teal" />
              <ListInput {...getListInputProps("tags")} />
              <TextInput
                title="Description"
                placeholder="Description"
                name="description"
                value={offer.description}
                onChange={handleInputChange}
                rows={4}
              />
              <Header content="Skills" sub color="teal" />
              <ListInput {...getListInputProps("skillsAndQualifications")} />
              <Header content="Adventages" sub color="teal" />
              <ListInput {...getListInputProps("advantages")} />
              <Header content="We Offer" sub color="teal" />
              <ListInput {...getListInputProps("weOffers")} />
              <Header content="Responsibilities" sub color="teal" />
              <ListInput {...getListInputProps("responsibilities")} />
              <Header content="Would Be a Plus" sub color="teal" />
              <ListInput {...getListInputProps("wouldBePlus")} />

              <div className="job-offer-form__control-buttons">
                <Button
                  disabled={isSubmitting}
                  loading={loading}
                  positive
                  type="submit"
                  content="Submit"
                />
                <Button
                  disabled={loading}
                  content={offer.enabled ? "Enabled" : "Hidden"}
                  type="button"
                  color="teal"
                  onClick={hideOrReviveOffer}
                />
                <Button
                  disabled={loading}
                  onClick={() => navigate("/offers")}
                  negative
                  type="button"
                  content="Cancel"
                />
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Container>
  );
}

export default observer(OfferForm);
