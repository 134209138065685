import { ChangeEvent } from "react";
import { Button } from "semantic-ui-react";

interface Props {
  array: string[];
  placeholder: string;
  removeInputField(index: number): void;
  addInputField(): void;
  handleInputChange(
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ): void;
}

function ListInput({
  array,
  removeInputField,
  addInputField,
  handleInputChange,
  placeholder,
}: Props) {
  return (
    <>
      {array.map((value, index) => (
        <div className="job-offer-form__input-container" key={index}>
          <textarea
            placeholder={placeholder}
            rows={1}
            value={value}
            onChange={(val) => {
              handleInputChange(val, index);
            }}
          />
          <Button
            content="Delete"
            negative
            onClick={() => removeInputField(index)}
            type="button"
            floated="right"
          />
        </div>
      ))}
      <div className="job-offer-form__new-field-button">
        <Button
          content="New field"
          positive
          type="button"
          onClick={() => addInputField()}
        />
      </div>
    </>
  );
}

export default ListInput;
