import { observer } from "mobx-react-lite";
import React from "react";
import LoadingComponent from "../../../components/loader/loader";
import { Tag } from "../../../models/tag";
import { useStore } from "../../../store/store";
import "./tags-list.scss";

interface TagsListProps {
  tags: [string | undefined, Tag][];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  activeTag: Tag | undefined;
  onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onClick: (tag: Tag) => void;
}

export default observer(function TagsList({
  tags,
  onChange,
  activeTag,
  onBlur,
  onClick,
}: TagsListProps) {
  const { tagStore } = useStore();
  const { loading } = tagStore;

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="tags-list">
      {tags.map(([id, tag]) => {
        const isActive = tag.id === activeTag?.id;

        return isActive ? (
          <input
            type="text"
            key={id}
            value={activeTag?.tag}
            onChange={onChange}
            onBlur={onBlur}
            className="tags-list__active"
            placeholder="Tag name"
          />
        ) : (
          <p
            className="tags-list__inactive"
            key={id}
            onClick={() => onClick(tag)}
          >
            {tag.tag}
          </p>
        );
      })}
    </div>
  );
});
