import axios, { AxiosError, AxiosResponse } from "axios";
import { Offer } from "../models/offer";
import { User, UserFormValues } from "../models/user";
import { store } from "../store/store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Message } from "../models/message";
import { CreatePost, Post } from "../models/blog";
import { Tag } from "../models/tag";
import { DBFile } from "../models/file";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.defaults.baseURL = "https://embrox.com/api/admin";

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    await sleep(1000);
    return response;
  },

  (error: AxiosError<any>) => {
    const { status, config, data } = error.response!;
    const navigate = useNavigate();
    switch (status) {
      case 400: {
        if (typeof data === "string") {
          toast.error(data);
        }
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          navigate("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        }
        break;
      }
      case 401: {
        toast.error("unauthorized");
        break;
      }
      case 404: {
        navigate("/not-found");
        break;
      }
      case 500: {
        navigate("/server-error");
        break;
      }
    }
    return Promise.reject(error);
  }
);

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body?: {}) =>
    axios.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Offers = {
  list: () => requests.get<Offer[]>("/vacancies"),
  details: (id: string) => requests.get<Offer>(`/vacancies/${id}`),
  create: (offer: Offer) => requests.post<Offer>("/vacancies", offer),
  update: (offer: Offer) =>
    requests.put<Offer>(`/vacancies/${offer.id}`, offer),
  delete: (id: string) => requests.delete<void>(`/vacancies/${id}`),
};

const Account = {
  login: (user: UserFormValues) => requests.post<User>("/login", user),
};

const Messages = {
  list: () => requests.get<Message[]>("/contacts"),
  details: (id: string) => requests.get<Message>(`/contacts/${id}`),
  read: (id: string) => requests.put<Message>(`/contacts/${id}/read`),
  delete: (id: string) => requests.delete<void>(`/contacts/${id}`),
};

const Tags = {
  list: () => requests.get<Tag[]>("/news/tags"),
  details: (id: string) => requests.get<Tag>(`/news/tags/${id}`),
  update: (tag: Tag) => requests.put<Tag>(`/news/tags/${tag.id}`, tag),
  create: (tagName: string) =>
    requests.post<Tag>(`/news/tags`, { tag: tagName }),
  delete: (id: string) => requests.delete<void>(`/news/tags/${id}`),
};

const SubTags = {
  list: () => requests.get<Tag[]>("/news/sub-tags"),
  details: (id: string) => requests.get<Tag>(`/news/sub-tags/${id}`),
  update: (tag: Tag) => requests.put<Tag>(`/news/sub-tags/${tag.id}`, tag),
  create: (subTagName: string) =>
    requests.post<Tag>(`/news/sub-tags`, { tag: subTagName }),
  delete: (id: string) => requests.delete<void>(`/news/sub-tags/${id}`),
};

const News = {
  list: () => requests.get<Post[]>("/news"),
  details: (id: string) => requests.get<Post>(`/news/${id}`),
  create: (news: CreatePost) => requests.post<Post>("/news", news),
  update: (id: string, news: CreatePost) =>
    requests.put<Post>(`/news/${id}`, news),
  delete: (id: string) => requests.delete<void>(`/news/${id}`),
};

const Images = {
  list: () => requests.get<DBFile[]>("/files"),
  details: (id: string) => requests.get<DBFile>(`/files/${id}`),
  createOne: (file: File) => {
    let formData = new FormData();
    formData.append("file", file);
    return requests.post<DBFile>("/file", formData);
  },
  createMany: (files: FormData) => requests.post<DBFile>("/files", files),
  delete: (id: string) => requests.delete<void>(`/files/${id}`),
};

const agent = {
  Account,
  Offers,
  Messages,
  News,
  Tags,
  SubTags,
  Images,
};

export default agent;
