import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Item, Segment } from "semantic-ui-react";
import LoadingComponent from "../../../components/loader/loader";
import { useStore } from "../../../store/store";

import "./contact-list.scss";

function ContactsList(): JSX.Element {
  const { messageStore } = useStore();
  const { messages, loadingInitial } = messageStore;

  const messageArray = Array.from(messages);

  if (loadingInitial) {
    return (
      <Segment style={{ overflow: "auto", maxHeight: 800, height: 800 }}>
        <LoadingComponent />
      </Segment>
    );
  }

  return (
    <Segment style={{ overflow: "auto", maxHeight: 800, height: 800 }}>
      {messageArray
        .sort((a, b) => Date.parse(b[1].date) - Date.parse(a[1].date))
        .map(([id, message]) => (
          <Link to={`/contact/?id=${id}`} key={id}>
            <Item
              className={
                message.read
                  ? "contact-list__item"
                  : "contact-list__item unread"
              }
            >
              <Item.Header>{message.fullName}</Item.Header>
              <Item.Header>{message.email}</Item.Header>
            </Item>
          </Link>
        ))}
    </Segment>
  );
}

export default observer(ContactsList);
