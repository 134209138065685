import React from "react";
import "./select-dropdown.scss";
import Select from "react-select";

interface SelectDropdownProps {
  options: { value: string; label: string }[];
  selectedOptions:
    | {
        value: string;
        label: string;
      }[]
    | {
        value: string;
        label: string;
      }
    | undefined;
  handleSelect: (data: any) => void;
  placeholder?: string;
  title?: string;
  isMulti: boolean;
  isSearchable: boolean;
}

export function SelectDropdown({
  options,
  selectedOptions,
  handleSelect,
  placeholder,
  title,
  isMulti,
  isSearchable,
}: SelectDropdownProps) {
  return (
    <div className="multi-select-dropdown">
      <h2>{title ? title : "Select Data"}</h2>
      <div className="multi-select-dropdown__container">
        <Select
          options={options}
          placeholder={`Select ${placeholder}`}
          value={selectedOptions}
          onChange={handleSelect}
          isSearchable={isSearchable}
          isMulti={isMulti}
        />
      </div>
    </div>
  );
}
