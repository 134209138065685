import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/axios";
import { CreatePost, Post } from "../models/blog";

export default class NewsStore {
  posts = new Map<string | undefined, Post>();
  selectedPost: Post | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  private setPost(post: Post) {
    this.posts.set(post.id, post);
  }

  private getPost(id: string) {
    return this.posts.get(id);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  loadPosts = async () => {
    this.loadingInitial = true;
    try {
      const postsArray = await agent.News.list();
      postsArray.forEach((post) => {
        this.setPost(post);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadPost = async (id: string) => {
    let post = this.getPost(id);
    if (post) {
      this.selectedPost = post;
      return post;
    } else {
      this.loadingInitial = true;
      try {
        post = await agent.News.details(id);
        this.setPost(post);
        runInAction(() => {
          this.selectedPost = post;
        });
        this.setLoadingInitial(false);
        return post;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  createPost = async (post: CreatePost) => {
    this.loading = true;
    try {
      const createdPost = await agent.News.create(post);
      runInAction(() => {
        this.posts.set(createdPost.id, createdPost);
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  editPost = async (id: string, post: CreatePost) => {
    this.loading = true;
    try {
      const editedOffer = await agent.News.update(id, post);
      runInAction(() => {
        this.posts.set(editedOffer.id, editedOffer);
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deletePost = async (id: string) => {
    this.loading = true;
    try {
      await agent.News.delete(id);
      runInAction(() => {
        this.posts.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
