import { createContext, useContext } from "react";
import NewsStore from "./blogStore";
import CommonStore from "./commonStore";
import MessageStore from "./messageStore";
import OfferStore from "./offerStore";
import TagStore from "./tagStore";
import UserStore from "./userStore";

interface Store {
  offerStore: OfferStore;
  messageStore: MessageStore;
  commonStore: CommonStore;
  userStore: UserStore;
  tagStore: TagStore;
  newsStore: NewsStore;
}

export const store: Store = {
  offerStore: new OfferStore(),
  messageStore: new MessageStore(),
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  tagStore: new TagStore(),
  newsStore: new NewsStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
