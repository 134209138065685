import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/axios";
import { User, UserFormValues } from "../models/user";
import { store } from "./store";

export default class UserStore {
  user: User | null = null;
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return !!this.user;
  }

  login = async (email: string, password: string) => {
    this.loading = true;
    try {
      const creds: UserFormValues = {
        credentials: {
          id: email,
          secret: password,
        },
      };
      const user = await agent.Account.login(creds);
      store.commonStore.setToken(user.accessToken);
      runInAction(() => {
        this.user = user;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      throw error;
    }
  };

  logout = () => {
    store.commonStore.setToken(null);
    window.localStorage.removeItem("jwt");
    this.user = null;
  };
}
