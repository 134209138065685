import React from "react";
import "./news-preview.scss";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Post } from "../../../models/blog";

interface NewsPreviewProps {
  news: Post;
}

export function NewsPreview({ news }: NewsPreviewProps) {
  return (
    <>
      <div className="news">
        <Link to={`/edit-blog/${news.id}`}>
          <img src={news.descriptionImage} alt="Blog" className="news__image" />
        </Link>
        <div className="news__info">
          <p className="main-tag">{news.mainTag.tagName}</p>
          <p className="divider">{"\xa0|\xa0"}</p>
          <p className="date">{dayjs(news.date).format("MMMM DD, YYYY")}</p>
        </div>
        <div className="news__description">
          <Link to={`/edit-blog/${news.id}`}>
            <p className="title">{news.title}</p>
          </Link>
          <p className="about">{news.description}</p>
        </div>
        <div className="news__labels">
          {news.subTags.map((subTag, index) => (
            <h5 className="label" key={subTag.tagId}>
              {subTag.tagName}
            </h5>
          ))}
        </div>
      </div>
    </>
  );
}
