/* eslint-disable import/no-anonymous-default-export */

import BlogForm from "./views/blog-form/blog-form";
import Blog from "./views/blog/blog";
import Contacts from "./views/contact/contact";
import Home from "./views/home/home";
import OfferForm from "./views/job-offer-form/job-offer-form";
import JobOffers from "./views/job-offers/job-offers";
import Login from "./views/login/login";
import Tags from "./views/tags/tags";

export default [
  { path: "/", Component: Login },
  { path: "/home", Component: Home },
  { path: "/offers", Component: JobOffers },
  { path: "/create-offer", Component: OfferForm },
  { path: "/edit-offer/:id", Component: OfferForm },
  { path: "/contact", Component: Contacts },
  { path: "/blog", Component: Blog },
  { path: "/create-blog", Component: BlogForm },
  { path: "/edit-blog/:id", Component: BlogForm },
  { path: "/tags", Component: Tags },
];
